import React from 'react'
import {
  Container,
  Divider,
  Grid,
  Header,
  List,
  Menu,
  Segment,
  Image,
} from 'semantic-ui-react'

const legalYear = new Date().getFullYear();

const FixedMenuLayout = () => (
  <div>
    <Menu fixed='top' inverted>
      <Container>
        <Menu.Item as='a' header>
          Veteriner Hekim Mehmet Kaplan
        </Menu.Item>
      </Container>
    </Menu>

    <Container text style={{ marginTop: '7em' }} textAlign='center'>
      <Grid>
        <Grid.Column computer={6} mobile={16}>
          <Image style={{width:'12.5em', maxWidth:'12.5em', height:'auto'}} alt='Veteriner Hekim Mehmet Kaplan' src={process.env.PUBLIC_URL + '/images/mehmet_kaplan.jpg'} rounded centered />
        </Grid.Column>
        <Grid.Column computer={10} mobile={16}>
          <Segment>
            <Header as='h1' >Vet. Hek. Mehmet KAPLAN</Header>
            <Header as='h2' >
              <a href="tel:+905425969805">0 542 596 98 05</a>
            </Header>
            <p>
              Atatürk Cad. Yunus Emre Sok. No:17/A<br></br>
              (Koçarlı Minibüs Garaj Girişi Karşısı)
            </p>
            <p>Koçarlı/AYDIN</p>
          </Segment>
        </Grid.Column>
      </Grid>
    </Container>


    <Segment inverted vertical style={{ margin: '5em 0em 0em', padding: '5em 0em' }}>
      <Container textAlign='center'>
        <Grid>
          <Grid.Column width={16}>
            <List horizontal inverted>
              <List.Item href='http://www.aydinvho.org/'>
                Aydın Veteriner Hekim Odası
              </List.Item>
              <List.Item href="http://www.tarsim.org.tr/trsmWeb/">
                Tarım Sigortaları Havuzu(Tarsim)
              </List.Item>
              <List.Item href="http://www.adsyb.org.tr/">
                Aydın Damızlık ve Sığır Yetiştiriciler Birliği
              </List.Item>
              <List.Item href="http://www.tvhb.org.tr/">
                Türk Veteriner Hekimler Birliği
              </List.Item>
              <List.Item href="http://www.haytap.org/">
                Hayvan Hakları Federasyonu
              </List.Item>
            </List>
          </Grid.Column>
        </Grid>


        <Divider inverted section />
        <List horizontal inverted divided link size='small'>
          <List.Item as='span'>
            © {legalYear} Veteriner Hekim Mehmet Kaplan
          </List.Item>
        </List>
      </Container>
    </Segment>
  </div>
)

export default FixedMenuLayout